<template>
    <div class="win-empDataTable">
        <van-popup v-model="showEmpDataTablePicker" position="bottom">
            <div style="width: 100%;overflow: hidden;padding: 10px;">
                <div class="table_box" style="overflow: auto;background: #fff;">
                    <table ref="table" class="table">
                        <tr class="top">
                            <th v-for="(value, j) in filedData" :key="j">
                                <div 
                                    style="margin: 0 auto;" 
                                    class="title" 
                                    :style="
                                        value.chnname ? { width: (value.chnname.length * 20)+20 + 'px' } : 'min-width:90px'
                                    "
                                >
                                    {{ value.chnname }}
                                </div>
                            </th>
                        </tr>
                        <tr @click="choiceSelect(choice)" class="cont" v-for="(choice, i) in tableData" :key="i">
                            <td v-for="(k, idx) in choice" :key="idx">
                                {{ k }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="bottomPage" style="height: 35px;display: flex;margin-top: 5px;">
                    <van-pagination 
                        class="empPage" 
                        v-model="currentPage" 
                        :total-items="total"
                        :show-page-size="3" 
                        force-ellipses 
                        @change="empDataTableChange" 
                    />
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { getEmpSelectGridFieldList } from "@api/wx";
import { paging } from '@/utils'
export default {
    props: {
        singleSel: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        return {
            userInfo,
            showEmpDataTablePicker:false,
            mid: 201,
            tableData:[],
            bfTableData:[],
            filedData:[],
            total: 0, // 进度条 总数
            currentPage: 1, // 当前页码
            pageSize: 10, // 每页多少条数据
            bigArray:[]
        }
    },
    methods:{
        getEmpData (data) {
            this.currentPage = 1;
            const newData = []
            getEmpSelectGridFieldList({
                moduleno: this.mid,
                username: this.userInfo.username,
                langid: localStorage.getItem('langid') || 0,
            }).then(res=>{
                this.filedData = res.data;
                for(let i = 0; i < data.length; i++){
                    let obj = {}
                    for(let j = 0; j < this.filedData.length; j++){
                        obj[this.filedData[j].fieldname] = data[i][this.filedData[j].fieldname]
                    }
                    newData.push(obj)
                }
                this.bfTableData = newData;
                this.bigArray = paging(this.pageSize, this.bfTableData);
                this.tableData = this.bigArray[this.currentPage - 1];

            })
        },
        empDataTableChange () {
            this.tableData = this.bigArray[this.currentPage - 1];
        },
        choiceSelect (obj) {
            this.$emit('choiceSelect',obj)
            this.showEmpDataTablePicker = false
        }
    }
}
</script>
<style lang="less" scoped>
.table_box {
    
    
    .table {
        width: 100%;
        border-spacing: 0px;
        border-left: 2px solid #ccc;
        tr {
            

            th,
            td {
                padding: 6px 10px;
                text-align: center;
                border-bottom: 2px solid #dcdfe6;
                border-right: 2px solid #dcdfe6;

                .comm_box {
                    display: flex;
                    justify-content: center;

                    .boolean_box {
                        .van-checkbox {
                            justify-content: center;

                            /deep/.van-checkbox__icon--checked {
                                .van-icon {
                                    color: #fff !important;
                                    background-color: #1989fa !important;
                                    border-color: #1989fa !important;
                                }
                            }

                            /deep/.van-checkbox__icon--disabled .van-icon {
                                background-color: #fff;
                            }
                        }
                    }

                    .input_box {
                        width: 150px;

                        .van-cell {
                            font-size: 24px;
                            border: 2px solid #d2d2d2;
                            border-radius: 10px;
                            padding: 8px 16px;
                        }
                    }
                }
            }
        }

        .top {
            position: sticky;
            top: 0;
            font-size: 28px;
            background: #e9f3fd;
            height: 64px;

            

            th {
                border-top: 2px solid #dcdfe6;
            }
        }

        .cont {
            height: 64px;
            font-size: 24px;
        }

        .cont:nth-child(2n-1) {
            background: #f5f5f5;
        }

        .nodata {
            td {
                font-size: 28px;
            }
        }
    }

    .isLookupcomboboxActiveBg {
        background: #9cd6ff !important;
    }
}
</style>